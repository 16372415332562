import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withPage } from "../../Page";
import ActionCreator from "../../ActionCreator";
import * as Widget from "../../Components/Widget2";
import Search from "../../Components/BlogSearch";
import ItemsList from "../../Components/BlogItemsList";
import BreadCrumbs from "../../Components/BlogBreadCrumbs";
import Sorting from "../../Components/BlogSorting";
import GridItem from "../../Components/BlogGridItem";
import ListItem from "../../Components/BlogItem";

class BlogListingPage extends React.Component {
  constructor(props) {
    super(props);
    let { blogs } = this.props.pageContext;
    this.state = {
      blogs: blogs,
      sortType: null,
      searchText: ""
    };
  }

  render() {
    let { blogs, sortType, searchText } = this.state;

    return (
      <Wrapper>
        <div className="items-right">
          <div className="items-search-desktop">
            <Search
              onChangeSearchText={text => this.setState({ searchText: text })}
            />
          </div>
          <div className="items-list">
            <ItemsList blogs={blogs} />
          </div>
        </div>

        <div className="items-content">
          <div className="items-toolbar">
            <div className="items-search-tablet">
              <Search
                onChangeSearchText={text => this.setState({ searchText: text })}
              />
            </div>
            <Widget.Row justify="space-between" align="center">
              <div className="items-breadcrumbs">
                <BreadCrumbs />
              </div>
              <div className="items-sort">
                <Sorting
                  blogs={blogs}
                  sortType={sortType}
                  onChangeSortType={type => this.setState({ sortType: type })}
                />
              </div>
            </Widget.Row>
          </div>

          <div className="items-collection-desktop">
            {this._getSearchResult(
              searchText,
              this._getSortReault(sortType, blogs)
            ).map((blog, i) => (
              <GridItem
                key={"grid-item" + i}
                data={blog}
                css="margin-bottom: 26px;"
              />
            ))}
          </div>

          <div className="items-collection-mobile">
            {this._getSearchResult(
              searchText,
              this._getSortReault(sortType, blogs)
            ).map((blog, i) => (
              <ListItem
                key={"list-item" + i}
                data={blog}
                css="margin-bottom: 26px;"
              />
            ))}
          </div>
        </div>
      </Wrapper>
    );
  }

  _getSortReault = (type, data) => {
    if (type === "-created") {
      return data.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
    } else if (type === "+created") {
      return data.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      );
    }

    return data;
  };

  _getSearchResult = (text, data) => {
    return data.filter(
      d =>
        d.title.indexOf(text.toLowerCase()) > -1 ||
        d.title.indexOf(text.toUpperCase()) > -1
    );
  };
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  max-width: 1260px;
  width: 100%;
  background-color: white;
  margin: 0 auto;
  padding: 5% 10px;

  & > .items-right {
    max-width: 300px;
    width: 100%;

    & .items-search-desktop {
      margin-bottom: 36px;
    }

    & .items-list {
    }
  }

  & > .items-content {
    max-width: 847px;
    width: 100%;
    & .items-toolbar {
      margin-bottom: 36px;

      & .items-search-tablet {
        display: none;
      }

      & .items-breadcrumbs {
      }

      & .items-sort {
      }
    }

    & .items-collection-desktop {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      max-width: 847px;
      width: 100%;
    }

    & .items-collection-mobile {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      max-width: 847px;
      width: 100%;
    }
  }

  @media screen and (max-width: 1024px) {
    justify-content: center;
    padding: 5%;
    & > .items-right {
      display: none;
    }

    & > .items-content {
      & .items-toolbar {
        margin-bottom: 29px;
        flex-direction: column;
        align-items: flex-start;

        & .items-search-tablet {
          margin-bottom: 18px;
          display: block;
          width: 300px;
        }

        & .items-breadcrumbs {
        }

        & .items-sort {
        }
      }
    }
  }

  @media screen and (max-width: 499px) {
    & > .items-content {
      & .items-toolbar {
        & .items-search-tablet {
          width: 100%;
        }
      }
      & .items-collection-desktop {
        display: none;
      }
    }
  }

  @media screen and (min-width: 500px) {
    & > .items-content {
      & .items-collection-mobile {
        display: none;
      }
    }
  }
`;

export default withPage(
  connect(
    null,
    ActionCreator
  )(BlogListingPage)
);
