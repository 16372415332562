import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import * as Icon from "./Icon";

class BlogBreadCrumbs extends Component {
  render() {
    let { navActions } = this.props;

    return (
      <Wrapper>
        <div className="crumb" onClick={() => navActions.push("/blogs")}>
          <Icon.Location size={20} color="#99bed8" />
          <span className="text">好文推薦</span>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  & .crumb {
    cursor: pointer;
    display: flex;
    align-items: center;

    & > .text {
      margin-left: 5px;
      letter-spacing: 3.08px;
      font-weight: 500;
      font-size: 16px;
      color: #16233d;
    }
  }
`;

export default connect(
  null,
  ActionCreator
)(BlogBreadCrumbs);
