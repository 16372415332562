import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import { mdy } from "../Utils/DateUtil";
import * as Widget from "./Widget2";

class BlogItem extends Component {
  render() {
    let { data, css, navActions } = this.props;

    return (
      <Wrapper
        img={data.thumbnail}
        css={css}
        onClick={() => navActions.push(data.path)}
      >
        <div className="image" />
        <div className="container">
          <div className="title">{this._limitWords(data.title)}</div>
          <Widget.Col>
            <div className="text">@{data.author}</div>
            <div className="text">{mdy(data.date)}</div>
          </Widget.Col>
        </div>
      </Wrapper>
    );
  }

  _limitWords = words => {
    if (words.length >= 35) {
      return words.slice(0, 36);
    }
    return words;
  };
}

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;

  & .image {
    width: 110px;
    height: 110px;
    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  & .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 16px;
  }

  & .title {
    font-weight: 500;
    font-size: 14px;
    color: #0a6fb7;
  }

  & .text {
    font-weight: 500;
    font-size: 14px;
    color: #b4b9c4;
  }

  ${props => props.css || ""};
`;

export default connect(
  null,
  ActionCreator
)(BlogItem);
