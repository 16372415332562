import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import { mdy } from "../Utils/DateUtil";
import * as Widget from "./Widget2";
import * as Icon from "./Icon";

class BlogGridItem extends Component {
  render() {
    let { data, css, navActions } = this.props;
    return (
      <Wrapper
        img={data.thumbnail}
        css={css}
        onClick={() => navActions.push(data.path)}
      >
        <div className="image" />
        <div className="container">
          <div className="title">{this._limitWords(data.title)}</div>
          <div className="text">@{data.author}</div>
          <Widget.Row align="center" justify="space-between">
            <div className="text">{mdy(data.date)}</div>
            <Widget.Row>
              <div className="button-text">閱讀更多</div>
              <Icon.ArrowForward size={20} color="#99bed8" />
            </Widget.Row>
          </Widget.Row>
        </div>
      </Wrapper>
    );
  }

  _limitWords = words => {
    if (words.length >= 40) {
      return words.slice(0, 41);
    }
    return words;
  };
}

const Wrapper = styled.div`
  max-width: 404px;
  width: 100%;
  cursor: pointer;

  & .image {
    width: 100%;
    height: 404px;
    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  & .container {
    padding: 20px 0px;
  }

  & .title {
    margin-bottom: 10px;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 14px;
    color: #0a6fb7;
  }

  & .text {
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 14px;
    color: #b4b9c4;
  }

  & .button-text {
    font-weight: 400;
    font-size: 14px;
    color: #99bed8;
  }

  @media screen and (max-width: 768px) {
    max-width: 300px;

    & .image {
      height: 300px;
    }
  }

  @media screen and (max-width: 600px) {
    max-width: 250px;

    & .image {
      height: 250px;
    }
  }

  ${props => props.css || ""};
`;

export default connect(
  null,
  ActionCreator
)(BlogGridItem);
