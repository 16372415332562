import React, { Component } from "react";
import styled from "styled-components";
import ListItem from "./BlogItem";

class BlogItemsList extends Component {
  render() {
    let { blogs } = this.props;
    return (
      <Wrapper>
        <div className="title-container">
          <div className="title">最新文章</div>
        </div>
        <div className="content-container">
          {this._getSortResult(blogs).map((blog, i) => (
            <ListItem
              key={"items-list" + i}
              data={blog}
              css={`
                margin-bottom: 20px;
                &:last-child {
                  margin-bottom: 0px;
                }
              `}
            />
          ))}
        </div>
      </Wrapper>
    );
  }

  _getSortResult = data => {
    return data.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
  };
}

const Wrapper = styled.div`
  width: 100%;

  & .title-container {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 38px;
    background: #0a6fb7;

    & > .title {
      font-size: 16px;
      color: white;
    }
  }

  & .content-container {
    border: 1px solid #0a6fb7;
    padding: 20px;
    width: 100%;
    max-height: 1000px;
    min-height: 366px;
    overflow-y: auto;
  }
`;

export default BlogItemsList;
