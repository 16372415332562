import React, { Component } from "react";
import styled from "styled-components";
import AppConfig from "../Config";

const themeColor = AppConfig.global.themeColor;

class BlogSorting extends Component {
  render() {
    let { blogs, sortType, onChangeSortType } = this.props;

    return (
      <Wrapper>
        <select
          value={sortType}
          onChange={e => {
            onChangeSortType(e.target.value);
          }}
        >
          <option key={-1} value={null} disabled selected>
            排列方式
          </option>
          <option value={"-created"}>發表日期(新 → 舊)</option>
          <option value={"+created"}>發表日期(舊 → 新)</option>
        </select>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-content: center;
  border: 1px solid ${themeColor};
  padding: 5px 8px;

  & > select {
    width: 100%;
    color: ${themeColor};
    font-size: 0.8em;
    border: 0;
    outline: 0;
    background: transparent;
  }
`;

export default BlogSorting;
