import React, { Component } from "react";
import styled from "styled-components";
import AppConfig from "../Config";
import * as Icon from "./Icon";

const themeColor = AppConfig.global.themeColor;

class BlogSearch extends Component {
  render() {
    let { onChangeSearchText } = this.props;

    return (
      <Wrapper>
        <SearchInput
          type="text"
          placeholder="搜尋"
          onChange={e => onChangeSearchText(e.target.value)}
        />
        <SearchButton>
          <Icon.Search size={20} color={themeColor} />
        </SearchButton>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  border: 1px solid ${themeColor};
  padding: 5px 0 5px 8px;
  width: 100%;
  height: 30px;
`;

const SearchInput = styled.input`
  outline: none;
  border: none;
  color: ${themeColor};
  flex: 1;
  &::placeholder {
    color: #99bed8;
  }
`;

const SearchButton = styled.div`
  cursor: pointer;
  flex: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  color: ${props => (props.selected ? `${themeColor}` : "#99BED8")};
  border-left: 1px solid ${themeColor};
  :hover {
    color: ${themeColor};
  }
`;

export default BlogSearch;
